import { useEffect } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useLazyQuery,
} from "@apollo/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import {
  faBox,
  faQuestionCircle,
  faComment,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@material-ui/core";

import { financial } from "../utils/common";
import { amplitudeIdentify, logAmplitudeEvent } from "../utils/analytics";
import {
  ACCOUNT_EVENTS,
  WALLET_EVENTS,
} from "../utils/analytics/events";
import { isLoggedInVar } from "../utils/apolloConfig";
import GET_ACCOUNT_DETAILS from "../api/queries/GET_ACCOUNT_DETAILS";
import IS_LOGGED_IN from "../api/queries/IS_LOGGED_IN";
import DELETE_COOKIES from "../api/mutations/DELETE_COOKIES";

import { LocationLink } from "./LocationLink";

const AccountMenu = () => {
  const pollInterval = 1000 * 30;
  const [getAccountDetails, { data: accountData, stopPolling }] = useLazyQuery(
    GET_ACCOUNT_DETAILS,
    {
      pollInterval: pollInterval,
    },
  );
  const { data: loginData } = useQuery(IS_LOGGED_IN);
  const location = useLocation();

  const client = useApolloClient();
  const navigate = useNavigate();
  const [deleteCookies] = useMutation(DELETE_COOKIES, {
    onCompleted() {
      logAmplitudeEvent(ACCOUNT_EVENTS.SIGN_OUT.SUCCEEDED);
      amplitudeIdentify(null);
      stopPolling();
      localStorage.clear();
      client.clearStore().then(() => {
        isLoggedInVar(false);
        navigate("/login", { state: { from: location } });
      });
    },
    onError() {
      logAmplitudeEvent(ACCOUNT_EVENTS.SIGN_OUT.FAILED);
    },
  });

  const logout = () => deleteCookies();

  useEffect(() => {
    if (loginData && loginData.isLoggedIn) {
      getAccountDetails();
    }
  }, [loginData, getAccountDetails]);

  return (
    <div className="bg-light">
      {loginData && loginData.isLoggedIn ? (
        <>
          <h3 className="p-3 mb-3">
            {accountData && accountData.myAccount ? (
              <span>Hi, {accountData.myAccount.username}!</span>
            ) : (
              <span>Your account</span>
            )}
          </h3>

          <h6 className="ml-3 mb-2">Your wallet</h6>
          <div className="bg-white border-top border-bottom text-center p-3 mb-4">
            <h4 className="font-weight-bold mb-0">
              {accountData && accountData.myAccount ? (
                <span>${financial(accountData.myAccount.balance)}</span>
              ) : (
                <CircularProgress size={32} />
              )}
            </h4>
            <small>Account balance</small>
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline btn-toolbar">
              <Link
                to="/account/deposit"
                className="btn btn-block btn-success font-weight-bold mr-2"
              >
                Deposit
              </Link>
              <Link
                to="/account/withdraw"
                className="btn btn-block btn-secondary text-speak font-weight-bold ml-2"
                onClick={() => {
                  logAmplitudeEvent(WALLET_EVENTS.WITHDRAWAL.FORM_VIEWED);
                }}
              >
                Withdraw
              </Link>
            </div>
          </div>

          <h6 className="text-speak ml-3 mb-2">Manage your collection</h6>
          <div className="list-group list-group-flush bg-white border-top border-bottom mb-4">
            <Link
              to="/ship-home/draft"
              className="list-group-item list-group-item-action text-speak"
            >
              <FontAwesomeIcon icon={faBox} className="text-primary mr-4" />
              Ship cards home
            </Link>
          </div>

          <h6 className="text-speak ml-3 mb-2">Your account</h6>
          <div className="list-group list-group-flush bg-white border-top border-bottom mb-4">
            <Link
              to="/account/notification-preferences"
              className="list-group-item list-group-item-action text-speak"
            >
              <FontAwesomeIcon icon={faBell} className="text-primary mr-4" />
              Notification Settings
            </Link>
          </div>
        </>
      ) : (
        <div className="text-center p-3 mb-4">
          <FontAwesomeIcon
            icon={faUserCircle}
            className="text-whisper fa-4x mb-3"
          />
          <h4 className="text-speak mb-3">
            Sign in to access your account options
          </h4>

          <LocationLink
            className="btn btn-block btn-success font-weight-bold mb-2"
            to="/signup"
          >
            Create a free account
          </LocationLink>
          <LocationLink
            className="btn btn-block btn-primary font-weight-bold"
            to="/login"
          >
            Log in
          </LocationLink>
        </div>
      )}

      <>
        <h6 className="text-speak ml-3 mb-2">Support</h6>
        <div className="list-group list-group-flush bg-white border-top border-bottom mb-4">
          <Link
            to="/faq"
            className="list-group-item list-group-item-action text-speak"
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="text-primary mr-4"
            />
            Frequently asked questions
          </Link>
          <Link
            to="/support"
            className="list-group-item list-group-item-action text-speak"
          >
            <FontAwesomeIcon icon={faComment} className="text-primary mr-4" />
            Contact support
          </Link>
        </div>

        {loginData && loginData.isLoggedIn ? (
          <div className="m-3">
            <Button
              className="btn btn-block btn-secondary text-speak font-weight-bold"
              onClick={() => logout()}
            >
              Log out
            </Button>
          </div>
        ) : (
          <div />
        )}
      </>
    </div>
  );
};

export default AccountMenu;
